import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faMagnifyingGlass, faEllipsisV, faStar, faClock, faCheck, faCheckDouble, faXmark, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import user from '../../assets/images/user.png'
import send from '../../assets/images/send.png'
import emoji from '../../assets/images/emoji-btn.png'
import plus_btn from '../../assets/images/plus-btn.png'
import voice_btn from '../../assets/images/voice-btn-white.png'
import send_btn from '../../assets/images/send.png'
import { useDispatch, useSelector } from 'react-redux';
import { sortByCreatedAt, useToast, utcToLocalConvertor } from '../../utils/helpers/CommFun';
import api from '../../services/Api';
import moment from 'moment';
import SocketContext from "../../context/SocketContext";
import { getMembersList, getUserList, setConvList, setGroupMembers } from '../../redux/actions/convListActions';
import { Dropdown, Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setAddGroupDrawer, setGroupInfoDrawer, setNewChatDrawer, setNewGroupDrawer } from '../../redux/actions/drawerActions';
import Picker from 'emoji-picker-react';
import { OnlineStatusContext } from '../../context/OnlineStatusContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import axios from 'axios';
import { colors } from '@mui/material';
import ContactInfo from './ContactInfo';
import { setContactInfoDrawer } from '../../redux/actions/drawerActions'
import '../../assets/css/drawer.css';
import GroupInfo from './GroupInfo';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'



function Chatwindow() {

    const toast = useToast();
    const isOnline = useContext(OnlineStatusContext);


    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const { currChat } = useSelector(state => state.conversation);

    const userToken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    const [message, setMessage] = useState("")
    const [OldMessage, setOldMessage] = useState([])
    const [offlineMessageList, setofflineMessageList] = useState([])
    const [messageId, setMessageId] = useState(0);
    const [isBlock, setIsBlock] = useState(parseInt(currChat.is_block));
    const [pickerToggler, setPickerToggler] = useState(false);
    const [star_messages, setStarMessages] = useState([]);
    const [showInputs, setShowInputs] = useState(false);

    //All Media
    const [recording, setRecording] = useState(false);
    const [File, setFile] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0)
    // console.log("Uploading", uploadProgress);
    const [ContentType, setContentType] = useState(1);
    const [uploadToken, setUploadToken] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [Uploading, setUploading] = useState(0);
    const audioChunks = useRef([]);

    const audioBlob = useRef(null);
    const [AudioURL, setAudioURL] = useState("");
    var mediaRecorderRef = useRef(null);
    var mediaRecorder = useRef(null);
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });

    var ContactInfoDrawerRef = useRef(null);
    var GroupInfoInfoDrawerRef = useRef(null);
    const { isContactInfoOpen, isGroupInfoOpen } = useSelector(state => state.drawer);
    //reverse pagintaion
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [messageEnd, setMessageEnd] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    //audio 
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0); // State to store the current time
    const audioRef = useRef(null);
  
    const handlePlay = () => {
      if (audioRef.current) {
        audioRef.current.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
            alert('There was an issue playing the audio. Please check the console for details.');
          });
      }
    };
  
    const handlePause = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const duration = audioRef.current.duration;
        const currentTime = audioRef.current.currentTime;
        const progressPercentage = (currentTime / duration) * 100;
        setProgress(progressPercentage);
        setCurrentTime(currentTime); // Update the current time
      }
    };
  
    const handleProgressClick = (event) => {
      if (audioRef.current) {
        const progressBar = event.currentTarget;
        const clickX = event.clientX - progressBar.getBoundingClientRect().left;
        const progressBarWidth = progressBar.offsetWidth;
        const newProgress = (clickX / progressBarWidth) * 100;
        const newTime = (newProgress / 100) * audioRef.current.duration;
  
        audioRef.current.currentTime = newTime;
  
        if (!isPlaying) {
          audioRef.current.play()
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error('Error playing audio after seeking:', error);
              alert('There was an issue playing the audio. Please check the console for details.');
            });
        }
      }
    };
  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };



    // For Smooth Scrolling
    const messagesEndRef = createRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };
    // console.log("Scroll to bottom", OldMessage);

    const dispatch = useDispatch();

    // const { messages } = useSelector(state => state.chat);
    const socket = useContext(SocketContext);

    useEffect(() => {
        oldMessageList();
        return () => {
            oldMessageList();
        };
    }, [socket]);

    useEffect(() => {
        scrollToBottom();
    }, [OldMessage, messagesEndRef.current])

    useEffect(() => {
        setOldMessage([]);
        oldMessageList();
        setMessage('');
        if (currChat.conv_type == 2) {
            dispatch(getMembersList(currChat.conv_id));
        } else {
            dispatch(setGroupMembers([]));
        }
    }, [currChat])


    // read all messages socket
    const eventData = {
        Authorization: "Bearer " + userToken,
        conv_id: `${currChat.conv_id}`,
    };
    socket.emit("mark_all_read", eventData);

    const blockToggler = async () => {
        try {
            let block = isBlock == 0 ? 1 : 0
            const body = {
                conv_id: currChat.conv_id,
                is_block: block
            }
            const response = await api.post(`/user_block`, body, userToken);

            if (response.code == 200) {
                setIsBlock(block);
                dispatch(setContactInfoDrawer(false))
            } else {
                // setListMessage(response.message);
                // setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    }

    const reportConversation = () => {
        Swal.fire({
            title: "Report User",
            text: "Are you sure you want to Report?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e20000",
            confirmButtonText: "Report",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const body = {
                        conv_id: currChat.conv_id,
                    }
                    const response = await api.post(`/report_conversation`, body, userToken);
                    console.log(response);
                    if (response.code == 200) {
                        toast.fire({
                            icon: "success",
                            title: response.message,
                        });
                    } else {
                        console.error(response.message);
                    }
                } catch (error) {
                    console.error("Error fetching messages:", error);
                }
            }
        });

    }

    // Fetch messages
    const oldMessageList = async (pageToFetch = 1) => {
        try {
            const response = await api.get(`/web_old_messages_list?conv_id=${currChat.conv_id}&message_id=${messageId}&size=10&page=${pageToFetch}`, userToken);
            if (response && response.code == 200 && response.result && response.result.length > 0) {
                setMessageEnd(false);
                setHasMore(true);
                setOldMessage((prevMessages) => {
                    const newMessages = response.result.filter(
                        newMessage => !prevMessages.some(existingMessage => existingMessage.message_id === newMessage.message_id)
                    );
                    return [...prevMessages, ...newMessages];
                });
                console.log("response", response.message)

            }
            else if (response.result.length === 0) {
                setHasMore(false);

            }
            else {
                setOldMessage([]);
                // setListMessage(response.message);
                // setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
        setLoading(false);
    };

    //on message receive socket
    const onSend_message = async (data) => {
        console.log("on receive message >>>>>>>>>>>>>>>`", data);
        if (data.message_id) {
            if (data.sender_id !== userData.id) {
                const updateMessageStatus = {
                    Authorization: "Bearer " + userToken,
                    message_ids: data.message_id,
                    status: "3"
                };
                socket.emit("update_message_status", updateMessageStatus);
            }

            setMessageId(data.message_id);
            setOldMessage((prevMessages) => {
                // // if (prevMessages.content_type == 3) {
                //     const updatedMessages = prevMessages.filter((message) => message.content_type === 3 ? message.client_id !== data[0].client_id : message );
                //     return [...updatedMessages , data];
                // // }
                return [...prevMessages, data]
            });

        }
    };

    // Message send socket
    const sendMessage = async () => {
        if (message !== "") {
            const messageData = {
                Authorization: "Bearer " + userToken,
                user_id: userData.id,
                conv_id: currChat.conv_id,
                content: message,
                content_type: 1,
                client_id: new Date().getTime(),
            };
            console.log("send", messageData);
            const offlineMessageData = {
                Authorization: "Bearer " + userToken,
                user_id: userData.id,
                sender_id: userData.id,
                conv_id: currChat.conv_id,
                content: message,
                content_type: 1,
                client_id: new Date().getTime(),
            };

            if (socket && isOnline) {
                try {
                    // setOldMessage((prevMessages) => { 
                    //     const filteredMessages = prevMessages.filter(message => !message.hasOwnProperty('Authorization') );
                    //     console.log("filteredMessages", filteredMessages);
                    //     return [...filteredMessages, messageData];
                    // });
                    await socket.emit("send_message", messageData);
                    setMessage("");
                    // console.log("Message Sent Successfuly");
                } catch (error) {
                    console.log("error", error)
                }
            } else {
                setofflineMessageList((prevList) => [...prevList, offlineMessageData]);
                setMessage("");
            }
        }
    };

    useEffect(() => {
        // console.log("Offline messages list updated:", offlineMessageList);
        localStorage.setItem("offlineMessageList", JSON.stringify(offlineMessageList));

        setOldMessage((prevMessages) => {
            const newMessages = offlineMessageList.filter(
                newMessage => !prevMessages.some(existingMessage => existingMessage.client_id === newMessage.client_id)
            );
            return [...prevMessages, ...newMessages];
        });
    }, [offlineMessageList]);

    useEffect(() => {
        if (isOnline && socket && offlineMessageList.length > 0) {
            const reversedOfflineMessages = offlineMessageList.slice().reverse();
            reversedOfflineMessages.forEach(async (offlineMessage) => {
                try {
                    await socket.emit("send_message", offlineMessage);
                    console.log("Offline message sent successfully:", offlineMessage);
                    localStorage.removeItem("offlineMessageList");
                    setofflineMessageList([]);
                    // setOldMessage((prevMessages) => {
                    //     const filteredOfflineMessages = offlineMessageList.filter(
                    //         message     => !message.Authorization
                    //     );
                    //     const newMessages = filteredOfflineMessages.filter(
                    //         newMessage => !prevMessages.some(existingMessage => existingMessage.client_id === newMessage.client_id)
                    //     );
                    //     return [...prevMessages, ...newMessages];
                    // });
                    setOldMessage([]);
                } catch (error) {
                    console.log("Failed to send offline message:", offlineMessage, error);
                }
            });
            setofflineMessageList([]);
        }
    }, [isOnline, socket]);


    const onget_message_status = async (data) => {
        console.log("onget_message_status", data);
        if (data.sender_id !== userData.id) {
            setOldMessage((prevMessages) => {
                const updatedMessages = prevMessages.map(message => {
                    if (message.message_id === data.message_id) {
                        // console.log('Updating message:', message); // Log the message being updated
                        return { ...message, message_status: data.message_status };
                    }
                    return message;
                });
                return updatedMessages;
            });
        }
    };


    const reload = (data) => {
        console.log(data);
        oldMessageList();
        dispatch(getUserList());
        dispatch(setAddGroupDrawer(false, false));
        dispatch(setNewGroupDrawer(false));
        dispatch(setNewChatDrawer(false));
    }

    const removeMessage = (data) => {
        console.log("Data received:", data);

        setOldMessage((prev) => {
            if (!Array.isArray(prev)) {
                console.error("State `prev` is not an array:", prev);
                return prev;
            }
            const updatedMessages = prev.filter((message) => message.message_id !== data[0].message_id);
            console.log("Updated Messages:", updatedMessages);
            return updatedMessages;
        });
    };
    const setNewConversation = (data) => {
        dispatch(getUserList());
        dispatch(setConvList(data));
        console.log("new conv", data);
    }

    //On Star Message Markd 
    const onmessageMarked = (socketResponse) => {
        setOldMessage((prev) => {
            // Extract message IDs from the socket response
            const messageIdsToMark = socketResponse.map(item => item.message_id);

            // Update the messages
            const updatedMessages = prev.map((message) => {
                console.log("Up>>>>", messageIdsToMark.includes(`${message.message_id}`));

                if (messageIdsToMark.includes(`${message.message_id}`)) {
                    return { ...message, is_star: 1 };
                }
                return message;
            });

            console.log("Updated Messages:", updatedMessages);
            return updatedMessages;
        });
    }

    //On Star Message UnMarkd
    const onmessageUnMarked = (socketResponse) => {
        setOldMessage((prev) => {
            // Extract message IDs from the socket response
            const messageIdsToMark = socketResponse.map(item => item.message_id);

            // Update the messages
            const updatedMessages = prev.map((message) => {
                console.log("Up<<<<", messageIdsToMark);
                if (messageIdsToMark.includes(message.message_id)) {
                    return { ...message, is_star: 0 };
                }
                return message;
            });

            console.log("Updated Messages:", updatedMessages);
            return updatedMessages;
        });
    }

    //All chat read
    const allChatRead = (socketResponse) => {
        setOldMessage((prev) => {

            // Update the messages
            const updatedMessages = prev.map((message) => {
                return { ...message, message_status: "3" };
            });

            console.log("Updated Messages:", updatedMessages);
            return updatedMessages;
        });
    }
    // Socket events 
    useEffect(() => {
        socket.on("all_chat_read", allChatRead);
        socket.on("message_received", onSend_message);
        socket.on("update_message_status", onget_message_status);
        socket.on("add_member", reload);
        socket.on("remove_member", reload);
        socket.on("create_group", reload);
        socket.on("delete_messages", removeMessage);
        socket.on("star_message_marked", onmessageMarked);
        socket.on("star_message_unmarked", onmessageUnMarked);
        // socket.on("create_conversation", setNewConversation);
        return () => {
            socket.off("message_received", onSend_message);
            socket.off("update_message_status", onget_message_status);
            socket.off("create_group", reload);
            socket.off("add_member", reload);
            socket.off("remove_member", reload);
            socket.off("delete_messages", removeMessage);
            socket.off("star_message_marked", onmessageMarked);
            socket.off("star_message_unmarked", onmessageUnMarked);
            // socket.off("create_conversation", setNewConversation);
        };
    }, [socket]);

    //Exit Group
    const exitGroup = async () => {
        try {
            const body = {
                conv_id: currChat.conv_id,
                user_id: userData.id,
            }
            console.log(body);
            const response = await api.post(`/remove_member`, body, userToken);

            if (response) {
                toast.fire({
                    icon: "success",
                    title: response.message,
                });

                console.log("online status", isOnline)

            } else {
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    }

    const handleSendMessage = (e) => {
        e.preventDefault();
        sendMessage();
    };

    // Handle Send emoji
    const onEmojiClick = (event, emojiObject) => {
        setMessage((prev) => (prev + event.emoji));
    };

    //Handle Star Message
    const handleStarMessage = async (messageId, convId, is_star) => {
        await setStarMessages(prevState => [
            ...prevState,
            {
                conv_id: `${convId}`,
                message_id: `${messageId}`,
                is_star: is_star
            }
        ]);
    }
    const handleStarSocket = async () => {
        console.log("socket", star_messages[0].is_star);
        let is_star_status = star_messages[0].is_star;
        setStarMessages([]);

        const starMessageData = {
            Authorization: "Bearer " + userToken,
            star_messages: star_messages,
            star_status: is_star_status == "1" ? "0" : "1" // 0 = unstar, 1 = star
        };

        console.log("send message", starMessageData);
        await socket.emit('star_unstar_message', starMessageData);
    }

    //Handle Delete Message
    const handleDeleteMessage = async (messageId, convId) => {
        const delete_messages = [{
            conv_id: convId,
            message_id: messageId
        }]
        const deleteMessageData = {
            Authorization: "Bearer " + userToken,
            delete_messages,
        };
        console.log("delete message", deleteMessageData);
        const data = await socket.emit('delete_messages', deleteMessageData);
        console.log(data);
    }

    //Handle Copy Message
    const handleCopyMessage = (content) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                toast.fire({
                    icon: "success",
                    title: " Message copied! ",
                });
            })
            .catch((err) => {
                toast.fire({
                    icon: "error",
                    title: err,
                });
            });
    };



    useEffect(() => {
        if (star_messages.length > 0) {
            handleStarSocket();
        }
    }, [star_messages]);

    //All media Upload 
    const handleMediaClick = () => {
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*, video/*, audio/*');
        fileInput.style.display = 'none';
        fileInput.addEventListener('change', handleFileChange);
        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0].type;
        const file = event.target.files[0];
        setUploadProgress(0);
        setFile(selectedFile);

        let ContentType = null;
        if (selectedFile.startsWith('image/')) {
            ContentType = 2; // 2 = image
        } else if (selectedFile.startsWith('audio/')) {
            ContentType = 3; // 3 = audio
        } else if (selectedFile.startsWith('video/')) {
            ContentType = 4; // 4 = video
        }
        const body = {
            conv_id: currChat.conv_id,
            content_type: ContentType,
            client_id: new Date().getTime(),
            file: event.target.files[0],
        }
        //setUploading(true);
        try {
            const response = api.postFormData(
                "/upload_media",
                body,
                userToken, {
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                    console.log("upload progress::::::: " + progress);
                }
            })
            console.log("response send successfully", response)
        }
        catch (error) {
            console.log("error while uploading media")
        }
    };

    const close = () => {
        if (uploadToken) {
            uploadToken.cancel('Upload canceled by user');
        }
        setSelectedFile(null);
        setUploading(false);
        setUploadProgress(0);
    }

    const startRecording = (event) => {
        const mimeTypes = ["audio/mp4", "audio/webm"].filter((type) =>
            MediaRecorder.isTypeSupported(type)
        );
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                mediaRecorder = new MediaRecorder(stream, { mimeType: mimeTypes[0] }
                );
                mediaRecorderRef.current = mediaRecorder;
                audioChunks.current = []; // Clear previous chunks


                mediaRecorder.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                    console.log("MediaRecorder", audioChunks.current);
                };

                mediaRecorder.onstop = async () => {
                    const audioBlobs = new Blob(audioChunks.current, {
                        type: "audio/mp3",
                    });
                    audioBlob.current = audioBlobs
                    console.log("onstop", audioChunks.current);
                    console.log("onstop", audioBlob);
                    setAudioURL(URL.createObjectURL(audioBlobs));
                    // await setAudioBlob(audioBlobs)
                    // audioChunks.current = [];
                };

                mediaRecorder.start();
                setRecording(true);
                setContentType(2);
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
            });
        // console.log("dfdfg", audioBlob);
    }

    const stopRecording = () => {
        if (mediaRecorderRef && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setRecording(false);

            // setAudioBlob([])
            // setContentType(1);
            setTime({ minutes: 0, seconds: 0 });
            console.log("stopRecording", audioChunks.current);
        }
    };

    const baseUrl = process.env.REACT_APP_BASE_URL;

    console.log("handleAudioUpload", OldMessage);
    const handleAudioUpload = () => {
        if (mediaRecorderRef && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setTime({ minutes: 0, seconds: 0 });

            setTimeout(() => {
                console.log("handleAudioUpload", audioBlob.current);
                try {
                    const bodyData = new FormData();
                    bodyData.append("content_type", 3);
                    bodyData.append("conv_id", currChat.conv_id);
                    bodyData.append("client_id", new Date().getTime());
                    bodyData.append("file", audioBlob.current);

                    // const audioData = {
                    //     "message_id": "",
                    //     "sender_id": userData.id,
                    //     "content": "",
                    //     "content_type": "3",
                    //     "client_id": new Date().getTime(),
                    //     "conv_id": currChat.conv_id,
                    //     "created_at": new Date(),
                    //     "message_status": "1",
                    //     "is_star": 0
                    // }

                    // setOldMessage((prevMessages) => {
                    //     return [...prevMessages, audioData];
                    // });

                    // Reset upload progress
                    setUploadProgress(0);

                    axios.post(baseUrl + '/upload_media', bodyData, {
                        headers: {
                            'Authorization': `Bearer ${userToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(progress);
                            console.log(`Upload Progress: ${progress}%`);
                        }
                    })
                        .then(response => {
                            console.log("Upload complete", response.data);
                            setUploadProgress(0);
                            // Handle the response from the server
                        })
                        .catch(error => {
                            console.error("Error uploading", error);
                            setUploadProgress(0);
                        });
                } catch (error) {
                    console.error("Error uploading", error);
                    setUploadProgress(0);
                }
            }, 100);
        }
    };

    const formatcounterTime = (value) => {
        return value.toString().padStart(2, '0');
    };

    useEffect(() => {
        let interval = null;
        if (recording) {
            interval = setInterval(() => {
                setTime(prevTime => {
                    let { minutes, seconds } = prevTime;
                    seconds += 1;
                    if (seconds === 60) {
                        seconds = 0;
                        minutes += 1;
                    }
                    return { minutes, seconds };
                });
            }, 1000);
        } else if (!recording && time.seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording, time.seconds]);
    const onpaginate = () => {
        setLoading(true);
        updatePage(1);
    };
    const updatePage = async (flag) => {
        if (flag === 1) {
            setPage((prevPage) => {
                const pageToFetch = prevPage + 1;
                oldMessageList(pageToFetch);
                return pageToFetch;
            });
        } else {
            oldMessageList(page);
        }
    }

    console.log("loading>>>>>", loading);
    return (
        <div className="col-md-7 col-lg-8 col-xxl-9 p-0">
            <div class="toggle-sidebar-section d-flex">
                {Object.keys(currChat).length !== 0 ?
                    <div className="chat-box-right">
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade active show">
                                <div className="content">
                                    <div className="contact-profile card-header">
                                        <div className='d-flex' onClick={() => currChat.conv_type == 2 ? dispatch(setGroupInfoDrawer(true)) : dispatch(setContactInfoDrawer(true))}>
                                            {currChat.profile_pic === null || currChat.group_icon === null ?
                                                <img
                                                    src={user}
                                                    alt="User Profile"
                                                />
                                                : <img
                                                    src={currChat.conv_type == 2 ? currChat.group_icon : currChat.profile_pic}
                                                    alt="User Profile"
                                                />
                                            }
                                            <div className="user-info">
                                                <h5>{currChat.conv_type == 2 ? currChat.group_name : currChat.username}</h5>
                                            </div>
                                        </div>
                                        <div className="call-setting-tab">
                                            {/* <div className="btn">
                                                <i><FontAwesomeIcon icon={faMagnifyingGlass} /></i>
                                            </div> */}
                                            <div
                                                className="btn profile-setting-btn dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                            >
                                                <i><FontAwesomeIcon icon={faEllipsisVertical} /></i>
                                            </div>
                                            <ul className="dropdown-menu dropdown-menu-dark">
                                                {currChat.conv_type == 2 ?
                                                    <>
                                                        <li>
                                                            <Link
                                                                onClick={() => {
                                                                    dispatch(setAddGroupDrawer(true, true)); //open drawer and for Add Member
                                                                }}
                                                                className="dropdown-item"
                                                            >
                                                                Add Member
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => exitGroup()} className="dropdown-item" >
                                                                Exit Group
                                                            </Link>
                                                        </li>
                                                    </>

                                                    :
                                                    <>
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                onClick={() => reportConversation()}
                                                                id="reportBtn"
                                                            >
                                                                Report to viewer
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => blockToggler()} className="dropdown-item" >
                                                                {isBlock == 0 ? "Block" : "Unblock"}
                                                            </Link>
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="card-body msg_card_body msg_card_body_2 "
                                        id="scrollableDiv"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column-reverse",

                                            overflow: 'auto',
                                        }}

                                    >
                                        {loading && (
                                            <div className="text-center mt-2">
                                                <span className="loader"></span>
                                            </div>
                                        )}
                                        <InfiniteScroll
                                            dataLength={OldMessage.length}
                                            next={onpaginate}
                                            //To put endMessage and loader to the top.

                                            inverse={true}
                                            hasMore={true}
                                            // loader={<h4>Loading...</h4>}
                                            scrollableTarget="scrollableDiv"

                                        >
                                            {(OldMessage && OldMessage.length > 0) ?
                                                [...OldMessage].sort(sortByCreatedAt).map((message, index) =>
                                                    message.sender_id == userData.id ?
                                                        <div key={index} className="d-flex justify-content-end">
                                                            <div className="message message-send">
                                                                {
                                                                    message.content_type == 1 ? (
                                                                        <div className="msg_cotainer_send">
                                                                            <div>
                                                                                {message.content}
                                                                                <div className="dropdown message-dropdown ">
                                                                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clip-path="url(#clip0_1343_2)">
                                                                                                <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_1343_2">
                                                                                                    <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg></span>
                                                                                    <ul class="dropdown-menu">
                                                                                        <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li>
                                                                                        <li><Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, message.is_star)} >Star message</Link></li>
                                                                                        <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 2 ? (
                                                                        <div className=" msg_cotainer_send img_cont_msg_send position-relative  ">
                                                                            <img
                                                                                // height="145" width="245"
                                                                                src={message.content}
                                                                                alt=""
                                                                            />
                                                                            <div className="dropdown message-dropdown ">
                                                                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_1343_2)">
                                                                                            <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg></span>
                                                                                <ul class="dropdown-menu dropdown-menu-dark">
                                                                                    {/* <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li> */}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>View message</Link></li>
                                                                                    <li><Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id)} >Star message</Link></li>
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 3 ? (
                                                                        <div className="msg_cotainer">
 <div className="voice-message-box">
      <div className="play-pause-btn">
        {!isPlaying ? (
          <button className="play-btn" onClick={handlePlay}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        ) : (
          <button className="pause-btn" onClick={handlePause}>
            <FontAwesomeIcon icon={faPause} />
          </button>
        )}
      </div>
      <div className="progress" onClick={handleProgressClick}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="timer-box">
        <span>
          {formatTime(currentTime)} {/* Display dynamic timestamp */}
        </span>
      </div>
      <div className="dropdown message-dropdown">
        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1343_2)">
              <path
                d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z"
                fill="#1F2128"
              />
            </g>
            <defs>
              <clipPath id="clip0_1343_2">
                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <ul className="dropdown-menu dropdown-menu-dark">
          <li><a className="dropdown-item" href="#">Star message</a></li>
          <li><a className="dropdown-item" href="#">Star message</a></li>
          <li><a className="dropdown-item" href="#">Star message</a></li>
        </ul>
      </div>
      <audio
        ref={audioRef}
        src={message.content} // Ensure this path is correct
        onTimeUpdate={handleTimeUpdate}
        preload="auto"
      />
    </div>
                                                                        </div>


                                                                    ) : message.content_type == 4 ? (
                                                                        <div className=" msg_cotainer_send video_cont_send position-relative ">
                                                                            <video controls>
                                                                                <source src={message.content} type="video/mp4" />
                                                                            </video>
                                                                            <div className="dropdown message-dropdown ">
                                                                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_1343_2)">
                                                                                            <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg></span>
                                                                                <ul class="dropdown-menu dropdown-menu-dark">
                                                                                    {/* <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li> */}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>View message</Link></li>
                                                                                    <li><Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id)} >Star message</Link></li>
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>)
                                                                        : null
                                                                }
                                                                <span class="msg_time mx-2">

                                                                    {message.is_star === 1 &&
                                                                        <FontAwesomeIcon icon={faStar} style={{ marginLeft: '5px' }} />
                                                                    }
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            message.message_status === "3" ? faCheckDouble :
                                                                                message.message_status === "2" ? faCheckDouble :
                                                                                    message.Authorization ? faClock : faCheck
                                                                        }
                                                                        style={{
                                                                            color:
                                                                                message.message_status === "3" ? '#0dcaf0' :
                                                                                    message.message_status === "2" ? 'white' :
                                                                                        message.Authorization ? 'white' : 'inherit',
                                                                            marginLeft: !message.Authorization && '5px'
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="msg_time_send">{moment(utcToLocalConvertor(message.created_at)).format("hh:mm a")}</span>
                                                            </div>
                                                        </div>
                                                        : <div key={index} className="d-flex justify-content-start">
                                                            <div className="message message-recive">
                                                                {
                                                                    message.content_type == 1 ? (
                                                                        <>
                                                                            <div className="group-chat-messages">
                                                                                <div className="sender-name">{currChat.conv_type == 2 && message.username}</div>
                                                                                <div className="msg_cotainer">
                                                                                    <div>
                                                                                        {message.content}
                                                                                    </div>
                                                                                    <div className="dropdown message-dropdown ">
                                                                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_1343_2)">
                                                                                                    <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_1343_2">
                                                                                                        <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg></span>
                                                                                        <ul class="dropdown-menu">
                                                                                            <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li>
                                                                                            <li><Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id)} >Star message</Link></li>
                                                                                            <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>``
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : message.content_type == 2 ? (
                                                                        <div className="group-chat-messages">
                                                                            <div className="sender-name">{currChat.conv_type == 2 && message.username}</div>
                                                                            <div className="img_cont_msg position-relative">
                                                                                <img
                                                                                    height="145" width="245"
                                                                                    src={message.content}
                                                                                    alt=""
                                                                                />
                                                                                {/* {selectedFile && (
                                                                                <>
                                                                                    <Progress
                                                                                        type="circle"
                                                                                        width={70}
                                                                                        percent={uploadProgress}
                                                                                    />
                                                                                    <FontAwesomeIcon icon={faCircleXmark} onClick={close} />
                                                                                </>
                                                                            )} */}
                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 3 ? (
                                                                        <div className="group-chat-messages">
                                                                            <div className="sender-name">{currChat.conv_type == 2 && message.username}</div>
                                                                            <div className="img_cont_msg position-relative ">
                                                                                <audio controls>
                                                                                    <source src={message.content} type="audio/ogg" />
                                                                                </audio>
                                                                                {selectedFile && (
                                                                                    <>
                                                                                        <Progress
                                                                                            type="circle"
                                                                                            width={70}
                                                                                            percent={uploadProgress}
                                                                                        />
                                                                                        <FontAwesomeIcon icon={faCircleXmark} onClick={close} />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>) : message.content_type == 4 ? (
                                                                            <div className="group-chat-messages">
                                                                                <div className="img_cont_msg position-relative ">
                                                                                    <video width="320" height="240" controls>
                                                                                        <source src={message.content} type="video/mp4" />
                                                                                    </video>
                                                                                    {/* {selectedFile && (
                                                                                    <>
                                                                                        <Progress
                                                                                            type="circle"
                                                                                            width={70}
                                                                                            percent={uploadProgress}
                                                                                        />
                                                                                        <FontAwesomeIcon icon={faCircleXmark} onClick={close} />
                                                                                    </>
                                                                                )} */}
                                                                                </div>
                                                                            </div>)
                                                                        : null
                                                                }
                                                                <span className="msg_time">{moment(utcToLocalConvertor(message.created_at)).format("hh:mm a")}
                                                                    {message.isOffline &&
                                                                        <FontAwesomeIcon icon={faClock} style={{ marginLeft: '5px' }} />
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                ) : null}
                                            <div key={currChat.conv_id} ref={messagesEndRef} ></div>
                                        </InfiniteScroll>

                                        {/* images  */}
                                        {/* <div className="d-flex justify-content-start ">
                                            <div className="message message-recive">
                                                <div className="img_cont_msg position-relative ">
                                                    <img
                                                        src="./assets/images/chat-box-img-2.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <span className="msg_time">8:55 AM</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    {currChat.is_removed == 0 ?
                                        <div className="box-footer">
                                            <div className="footer-left">
                                                {pickerToggler ? <Picker onEmojiClick={onEmojiClick} style={{ position: 'absolute', bottom: '80px' }} /> : ''}
                                                <img
                                                    onClick={() => { setPickerToggler(!pickerToggler) }}
                                                    src={emoji}
                                                    alt=""
                                                    className="footer-img"
                                                />
                                            </div>
                                            <img src={plus_btn} alt="Add Media" style={{ cursor: 'pointer' }} onClick={handleMediaClick} />
                                            <form onSubmit={handleSendMessage}>
                                                <div className="input-group pb-0 ">
                                                    <div className="input-field">
                                                        {uploadProgress > 0 && (
                                                            <Progress percent={uploadProgress} status="active" />
                                                        )}
                                                        <input
                                                            className="test"
                                                            name='message'
                                                            placeholder="Type Message..."
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        />
                                                        <div class="chat-control">
                                                            <img
                                                                onClick={recording ? handleAudioUpload : handleSendMessage}
                                                                src={send_btn}
                                                                alt=""
                                                                className="footer-img"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="footer-right">
                                                {recording &&
                                                    <div>
                                                        {formatcounterTime(time.minutes)}:{formatcounterTime(time.seconds)}
                                                    </div>
                                                }
                                                {!recording &&
                                                    <img
                                                        src={voice_btn}
                                                        alt=""
                                                        className="footer-img"
                                                        onClick={startRecording}
                                                    />
                                                }
                                                {recording && (
                                                    <>
                                                        <div
                                                            className="link-doc mx-2"
                                                            style={{ "cursor": "pointer" }}
                                                        >
                                                            <span
                                                                onClick={stopRecording}
                                                            >
                                                                <FontAwesomeIcon icon={faXmark} style={{ "height": "30px", "width": "30px", "maxWidth": "100%" }} />
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div> :
                                        <div>
                                            <p>You can't Send Message to This Group Because You're No Longer a Member.</p>
                                        </div>}

                                </div>
                            </div>
                            {/* Report user modal start  */}
                            <div
                                className="modal "
                                id="reportUser"
                                role="dialog"
                                data-bs-backdrop="static"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    {/* Modal content*/}
                                    <div className="modal-content payment-card">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                        <div className="modal-header">
                                            <h5 className="text-center">Report User</h5>
                                        </div>
                                        <div className="modal-body">
                                            <form>
                                                <div className="common-form">
                                                    <div className="input-group flex-column">
                                                        <textarea
                                                            name=""
                                                            id=""
                                                            rows={5}
                                                            placeholder="Enter Description"
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="btn w-100  orange-color shadow-none"
                                                >
                                                    Report to Viewer
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Report user modal end */}
                        </div>

                    </div >
                    : null
                }

                {/* ContactInfo */}
                <div ref={ContactInfoDrawerRef} className={`drawer-right ${isContactInfoOpen ? 'open' : ''}`}>
                    <ContactInfo currChat={currChat} blockToggler={blockToggler} reportConversation={reportConversation} />
                </div>

                {/* GroupInfo */}
                <div ref={GroupInfoInfoDrawerRef} className={`drawer-right ${isGroupInfoOpen ? 'open' : ''}`}>
                    <GroupInfo currChat={currChat} reportConversation={reportConversation} exitGroup={exitGroup} />
                </div>

            </div>
        </div >
    )
}

export default Chatwindow