import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import { loginSuccess } from './reducers/authReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Load user from local storage and dispatch login success action
const usertoken = localStorage.getItem('token');
if (usertoken) {
  store.dispatch(loginSuccess(usertoken));
}

export default store;
