// In reducers/drawerReducer.js

import { SET_NEWCHAT_OPEN, SET_ADDGROUP_OPEN, SET_NEWGROUP_OPEN, SET_SETTINGS_OPEN , SET_CONTACTINFO_OPEN , SET_GROUPINFO_OPEN ,SET_PROFILE_OPEN, SET_STARRED_OPEN} from '../actions/drawerActions';

const initialState = {
    isNewChatOpen: false,
    isAddGroupOpen: false,
    isNewGroupOpen: false,
    isSettingsOpen:false,
    isContactInfoOpen:false,
    isGroupInfoOpen:false,
    isAddMember:false,
    isProfileOpen: false,
    isStarredOpen: false,
};

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS_OPEN:
            return {
                ...state,
                isSettingsOpen: action.payload,
            };
        case SET_ADDGROUP_OPEN:
            return {
                ...state,
                isAddGroupOpen: action.payload.currState,
                isAddMember: action.payload.isAddMember
            };
        case SET_NEWGROUP_OPEN:
            return {
                ...state,
                isNewGroupOpen: action.payload
            };
        case SET_NEWCHAT_OPEN:
            return {
                ...state,
                isNewChatOpen: action.payload
            };
        case SET_CONTACTINFO_OPEN:
            return {
                ...state,
                isContactInfoOpen: action.payload
            };
        case SET_GROUPINFO_OPEN:
            return {
                ...state,
                isGroupInfoOpen: action.payload
            }
        case SET_PROFILE_OPEN:
            return {
                ...state,
                isProfileOpen: action.payload
            };
        case SET_STARRED_OPEN:
            return {
                ...state,
                isStarredOpen: action.payload
            };
        default:
            return state;
    }
};

export default drawerReducer;
