import React, { useRef, useState, useEffect } from "react";
import zybraLogo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/Api.js";
import { useToast, validatePhoneNumber } from "../../utils/helpers/CommFun.js";
import { useDispatch } from 'react-redux';
import { login } from "../../redux/actions/authActions.js";
import moment from "moment";

function Otp() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const userData = JSON.parse(sessionStorage.getItem("user_data")) || "";

  const length = 4;
  const inputRef = useRef(Array(length).fill(null));
  const [OTP, setOTP] = useState(Array(length).fill(""));
  const [timeLeft, setTimeLeft] = useState(60);

  //for Otp entering
  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (index < length - 1 && input !== "") {
      inputRef.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index !== 0 && OTP[index] === "") {
      inputRef.current[index - 1].focus();
      const newPin = [...OTP];
      newPin[index] = "";
      setOTP(newPin);
    }
  };
  useEffect(() => {
    if (timeLeft === 0) return;

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);
  const restartTimer = () => {
    setTimeLeft(60);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(OTP, navigate));
  };
  const resendOtp = async (event) => {
    event.preventDefault();
    if (!validatePhoneNumber(userData.phone)) {
      toast.fire({
        icon: "warning",
        title: "Please enter valid phone number",
      });
    } else {
      try {
        const data = {
          phone: userData.phone,
        };
        const response = await api.post('/resend_otp', data);
        if (response.code === 200) {
          toast.fire({
            icon: "success",
            title: response.message,
          });
          restartTimer();
        } else {
          toast.fire({
            icon: "error",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
        }
      } catch (error) {
        console.error("error", error);
      }
    }
  };
  useEffect(() => {
    document.title = "OTP Page"
  }, []);

  return (
    <div className="main-wrapper">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-6 col-md-7 col-lg-5">
            <div className="login-box verification-box">
              <div className="card border-0 ">
                <div className="card-title text-center ">
                  <div className="logo-box">
                    <Link to="/">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                  </div>
                  <h3>Enter Verification Code</h3>
                  <p>
                    We have sent you a 4 digit verification <br /> code on
                  </p>
                  <p className="number">+91 {userData.phone}</p>
                  <div className="change-nb">
                    <Link to="/login">Change Number</Link>
                  </div>
                  {timeLeft === 0 ?
                    <div className="change-nb">
                      <Link onClick={resendOtp}>Resend OTP</Link>
                    </div>
                    : <p className="number">{moment.utc(timeLeft * 1000).format('mm:ss')}</p>
                  }
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <p className="otp">Enter OTP</p>
                    <div className="otp-field">
                      {OTP.map((value, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          ref={(el) => (inputRef.current[index] = el)}
                          value={value}
                          onChange={(e) =>
                            handleTextChange(e.target.value, index)
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      ))}
                      {/* <input type="tel" maxLength={1} />
                                            <input type="tel" maxLength={1} />
                                            <input type="tel" maxLength={1} /> */}
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn  main-bg">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
