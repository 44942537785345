import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './redux/store';
import Login from "./pages/Auth/Login";
import Otp from "./pages/Auth/Otp";
import PrivateRoute from "./components/PrivateRoute";
import Chat from "./pages/Chat/Chat";
import { io } from "socket.io-client";
import 'bootstrap/dist/js/bootstrap.bundle.min';


function App() {

  return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<Otp />} />
            <Route element={<PrivateRoute />}>
              <Route path="/chat" element={<Chat />} />
            </Route>
          </Routes>
        </Router>
      </Provider>

  );
}

export default App;
