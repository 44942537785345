import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import drawerReducer from './drawerReducer';
import convListReducer from './convListReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  conversation:convListReducer,
});

export default rootReducer;
