import React, { useState, useEffect, useRef } from 'react'
import camera from "../assets/images/camera.png"
import profileImage from "../assets/images/profile-img.png"
import ok from "../assets/images/ok.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux'
import { setProfileDrawer } from "../redux/actions/drawerActions"
import { useToast } from "../utils/helpers/CommFun"
import api from "../services/Api"
import emoji from '../assets/images/emoji-btn.png'
import Picker from 'emoji-picker-react';
function Profile() {
    const toast = useToast();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [profilePic, setProfilePic] = useState(null)
    const [isEditingName, setIsEditingName] = useState(false);

    const [showImageIcon, setShowImageIcon] = useState(true);
    const [file, setFile] = useState('')
    const userToken = localStorage.getItem('token')
    const emojiRef = useRef(null);
    const pickerRef = useRef(null);
    const [pickerToggler, setPickerToggler] = useState(false);
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        console.log("userData", userData.profile_pic)
        setName(userData.username);
        setProfilePic(userData.profile_pic);

    }, []);
    const toggleEditName = () => {
        setIsEditingName(!isEditingName);
        setShowImageIcon(!isEditingName); // Toggle image visibility based on edit mode
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setProfilePic(selectedFile);
        } else {
            setProfilePic(null);
        }
    };
    const handleClickOutside = (event) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target) &&
            !emojiRef.current.contains(event.target)
        ) {
            setPickerToggler(false);
        }
        setShowImageIcon(true);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const onEmojiClick = (event, emojiObject) => {
        setName((prev) => (prev + event.emoji));
        setShowImageIcon(false);
    };


    const name_change = (e) => {
        setName(e.target.value)
    }
    const submit = async () => {
        if (name === '') {
            toast.fire({
                icon: "error",
                title: "please enter name",
            });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('username', name);
            if (profilePic instanceof File) {
                formData.append('image', profilePic);
            }
            const response = await api.postFormData(`/update_profile`, formData, userToken);

            if (response.code == 200) {
                const userDataString = localStorage.getItem('userData');
                const userData = userDataString ? JSON.parse(userDataString) : {}
                const updatedUserData = {
                    ...userData,
                    username: response.result.response.username,
                    profile_pic: response.result.response.profile_pic,
                };
                localStorage.setItem('userData', JSON.stringify(updatedUserData));
                toast.fire({
                    icon: "success",
                    title: "Profile updated successfully",
                });
                dispatch(setProfileDrawer(false))
            } else {
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }


    }
    const getProfilePicSrc = () => {
        if (profilePic === null) {
            return profileImage;
        } else if (profilePic instanceof File) {
            return URL.createObjectURL(profilePic);
        } else if (typeof profilePic === 'string' && (profilePic.startsWith('http') || profilePic.startsWith('https'))) {
            return profilePic;
        } else {
            return profileImage;
        }
    };
    return (
        <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn" onClick={() => dispatch(setProfileDrawer(false))} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>Profile</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left profile-setting">
                <div className="profile-info-box se">
                    <div className="row">
                        <div className="new-group-box d-block">
                            <div className="col-12">
                                <div className="input-group flex-column justify-content-center">

                                    <div className="image add-image">

                                        <div className="change-img add-group-icon">

                                            <img src={camera} alt="" className="camera" />
                                            <span>Change <br />Profile Photo</span>


                                        </div>
                                        <img src={getProfilePicSrc()} alt="Profile" className="profile-update" />

                                        <input type="file" className="form-control" id="" placeholder=""


                                            accept="image/gif, image/jpeg, image/png" onChange={handleFileChange} />
                                    </div>


                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group">
                                    <label className="form-label"></label>                                 
                                        <div style={{ margin: '0 0 0 -55px', padding: '50px', position: 'absolute'}}>
                                            <input type="text" className="form-control" id="" value={name} alt="" onChange={name_change} />                                            {pickerToggler && (
                                                <div ref={pickerRef} style={{ position: 'absolute' }}>
                                                    <Picker onEmojiClick={onEmojiClick} />
                                                </div>
                                            )}
                                            <img
                                                ref={emojiRef}
                                                onClick={() => { setPickerToggler(!pickerToggler) }}
                                                src={emoji}
                                                alt="Emoji"
                                                className="footer-img"
                                            />
                                        </div>


                                    <div className="emoji-box">
                                        <i className="fa-solid fa-pen"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="next-btn-box">
                    <div className="img-box">
                        <img src={ok} alt="" onClick={submit} />
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Profile;