import axios from "axios";

// Base URL
const baseUrl = process.env.REACT_APP_BASE_URL;

// Create API headers
const createHeaders = (token) => {
    const headers = {
        "Content-Type": "application/json", // Adjust content type as needed
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return headers;
};

// Generic API request function
const request = async (method, url, data = null, token = null) => {
    try {
        const response = await axios({
            method,
            url: baseUrl + url,
            headers: createHeaders(token),
            data,
        });
        return response.data;
    } catch (error) {
        throw error; // Re-throw for handling in the calling component
    }
};

// For GET requests
const get = (url, token) => request('get', url, null, token);

// For POST requests
const post = (url, data, token) => request('post', url, data, token);

// For POST Form requests
const postFormData = async (url, formData, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${baseUrl}${url}`, formData, config);
    return response.data; // Return the response data if successful
  } catch (error) {
    console.error(
      "Error while posting form data:",
      error.response || error.message
    );
    throw new Error("Failed to post form data"); 
  }
};


// API object
const api = {
    get,
    post,
    postFormData
};

export default api;
