import React from 'react'
import Chatlist from '../../components/Chat/Chatlist'

function Chat() {
  
  return (
    <section id="main-box">
      <div className="container-box">
        <div className="right-sidebar">
          <div className="main-contant">
            <div className="container-fluid">
              <section className="contant-box" id="chatSection">
                <div className="row">
                  <Chatlist />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Chat