import React, { useContext, useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setAddGroupDrawer, setNewChatDrawer, setNewGroupDrawer } from '../../redux/actions/drawerActions';
import api from '../../services/Api'
import SocketContext from '../../context/SocketContext';
import Picker from 'emoji-picker-react';
import emoji from '../../assets/images/emoji-btn.png'
import okIcon from '../../assets/images/ok.png'
import camera from '../../assets/images/camera.png'
import groupImg from '../../assets/images/group-img.png'
import axios from 'axios';

function NewGroup() {
    const dispatch = useDispatch();
    const [groupName, setGroupName] = useState('');
    const [error, seterror] = useState(null);
    const [groupIcon, setGroupIcon] = useState(null);

    //get token
    const userToken = localStorage.getItem('token')
    // const userData = useSelector((state) => state.auth.user);
    const userData = JSON.parse(localStorage.getItem('userData'))

    const { newGroupList } = useSelector(state => state.conversation);
    const { isNewGroupOpen } = useSelector(state => state.drawer);
    let tempfile = null;
    const socket = useContext(SocketContext);
    const emojiRef = useRef(null);
    const pickerRef = useRef(null);
    const [pickerToggler, setPickerToggler] = useState(false);

    useEffect(() => {
        if (!isNewGroupOpen) {
            setGroupIcon(null);
            setGroupName('');
        }
    }, [isNewGroupOpen])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                pickerRef.current &&
                !pickerRef.current.contains(event.target) &&
                emojiRef.current &&
                !emojiRef.current.contains(event.target)
            ) {
                setPickerToggler(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onEmojiClick = (event, emojiObject) => {
        setGroupName((prev) => (prev + event.emoji));
    };

    const createNewGroup = async () => {
        let groupList = [...newGroupList, userData.id];
        // const body = {
        //     Authorization: "Bearer " + userToken,
        //     user_id: userData.id,
        //     participant_ids: groupList.toString(),
        //     group_name: groupName,
        //     group_icon: groupIcon || null ,
        //     conv_client_id: Date.now(),
        // };
        try {
            const bodyData = new FormData();
            bodyData.append("user_id", userData.id);
            bodyData.append("participant_ids", groupList.toString());
            bodyData.append("group_name", groupName);
            bodyData.append("group_icon", groupIcon || null);
            bodyData.append("conv_client_id", Date.now());

            console.log("Creating new group", bodyData);
            const response = api.postFormData("/create_group", bodyData, userToken)
            // console.log("Created new group success", response);
            dispatch(setNewGroupDrawer(false))
            dispatch(setNewChatDrawer(false))
            dispatch(setAddGroupDrawer(false, false));

        } catch (error) {
            console.error("Error uploading", error);

        }

        // if (socket) {
        //     try {
        //         const data = await socket.emit("create_group", body);
        //         dispatch(setNewGroupDrawer(false))
        //         dispatch(setNewChatDrawer(false))
        //         dispatch(setAddGroupDrawer(false, false));
        //     } catch (error) {
        //         console.error("group not created", error)
        //     }
        // }
    };


    return (
        <div className="left-user-box w-100">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn mx-3" onClick={() => dispatch(setNewGroupDrawer(false))} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>New group</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left new-group">
                <div className="selected-user-tab">
                    <div className="row">
                        <div className="new-group-box d-block">
                            <div className="col-12">
                                <div className="input-group flex-column justify-content-center">
                                    <div className="image add-image">
                                        {groupIcon ? <img src={URL.createObjectURL(groupIcon)} alt="group-img" className="profile-update" /> :
                                            <>
                                                <img src={groupImg} alt="group-img" className="profile-update" />
                                                <div className="change-img add-group-icon">
                                                    <img src={camera} alt="" className="camera" />
                                                    <span>Add group <br /> icon</span>
                                                </div>
                                            </>
                                        }
                                        <input type="file" className="form-control" accept="image/gif, image/jpeg, image/png" onChange={(event) => { setGroupIcon(event.target.files[0]) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Group subject (optional)" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                                    <div className='emoji-box'>|
                                        {pickerToggler && (
                                            <div ref={pickerRef} style={{ position: 'absolute' }}>
                                                <Picker onEmojiClick={onEmojiClick} />
                                            </div>
                                        )}
                                        <img
                                            ref={emojiRef}
                                            onClick={() => { setPickerToggler(!pickerToggler) }}
                                            src={emoji}
                                            alt="Emoji"
                                            className="footer-img"
                                        />
                                    </div>
                                    <div className="emoji-box">
                                        <i className="fa-solid fa-face-smile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="next-btn-box" onClick={() => { createNewGroup() }}>
                    <div className="img-box">
                        {/* <i><FontAwesomeIcon icon={faArrowRight} /></i> */}
                        <div className="img-box">
                            <img src={okIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewGroup