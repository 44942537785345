// src/redux/actions/authActions.js
import api from '../../services/Api';
import { useToast } from '../../utils/helpers/CommFun';
import { loginRequest, loginSuccess, loginFailure, logout } from '../reducers/authReducer';

export const login = (credentials , navigate) => async (dispatch) => {

    const userData = JSON.parse(sessionStorage.getItem("user_data")) || "";
    const toast = useToast();


    dispatch(loginRequest(credentials));
    try {
        const body = {
            id: userData.id,
            user_type: userData.type,
            otp: credentials.join("")
            // is_web_login:1
        };

        const response = await api.post("/user_otp_verify", body);
        if (response.code == 200) {
            toast.fire({
                icon: "success",
                title: response.message,
            });
            localStorage.setItem("token", response.result.token);
            localStorage.setItem("userData", JSON.stringify(response.result));
            sessionStorage.removeItem("user_data");
            navigate("/chat");
        } else {
            toast.fire({
                icon: "error",
                title: response.message,
            });
        }
        dispatch(loginSuccess(response.result));
    } catch (error) {
        dispatch(loginFailure(error.message));
    }
};

export const performLogout = (navigate) => (dispatch) => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
};
