import React, { useState, useEffect, useSelecter } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../services/Api'
import { setStarredDrawer } from "../../redux/actions/drawerActions"
function StarredMessages() {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [error, setError] = useState('')
    const [profilepic, setProfilepic] = useState('')
    const [OldMessage, setOldMessage] = useState('')
    const userData = useSelector((state) => state.auth.user);
    const [MessageList, setMessageList] = useState([]);
    //get the token 
    const usertoken = localStorage.getItem('token')
    const { conversationList } = useSelector(state => state.conversation);

    const getMessageList = async () => {
        try {
            setError(null);
            setMessageList([]);
            const response = await api.get("/starred_message_list", usertoken);
            if (response.code === 200) {
                setMessageList(response.result);
            } else {
                setError('Failed to fetch Dashboard list');
            }
        } catch (error) {
            setError('An error occurred while fetching the dashboard list');
            console.error(error);
        } finally {
        }
    }

    // const conversationIds = new Set(conversationList.map(conversation => conversation.conv_id));
    // const checkConversations = async() => {
    //     const matchedIds = MessageList
    //         .filter(responseItem => conversationIds.has(responseItem.conv_id))
    //         .map(responseItem => ({
    //             message_id: responseItem.message_id,
    //             conv_id: responseItem.conv_id
    //         }));
    //     console.log('Matching IDs in conversation list:', matchedIds);
    //     MessageList.map(id => matchedIds)
    //     try {
    //         for (const { message_id, conv_id } of matchedIds) {
    //             const response = await api.get(`/web_old_messages_list?conv_id=${conv_id}&message_id=${message_id}`,usertoken);
    //             if (response && response.code === 200 && response.result && response.result.length > 0) {
    //                 // setOldMessage(prevMessages => {
    //                 //     const newMessages = response.result.filter(
    //                 //         newMessage => !prevMessages.some(existingMessage => existingMessage.message_id === newMessage.message_id)
    //                 //     );
    //                 //     return [...prevMessages, ...newMessages];
    //                 // });
    //                 console.log("Response for conv_id:", conv_id, "message_id:", message_id, response);  
    //             } else {
    //                 console.log('No old messages found for conv_id:', conv_id, 'message_id:', message_id);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error fetching messages:', error);
    //     }
    // }

    // checkConversations()





    useEffect(() => {
        getMessageList();
    }, []);

    return (
        <section id="main-box">
            <div className="container-box">
                <div className="right-sidebar">
                    <div className="main-contant">
                        <div className="container-fluid">
                            <section className="contant-box" id="chatSection">
                                <div className="row">

                                    <div className="left-user-box">
                                        <div className="contact-profile big ">
                                            <div className="call-setting-tab ms-0 justify-content-between w-100">
                                                <div className="ms-0 btn" onClick={() => dispatch(setStarredDrawer(false))}>
                                                    <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                                                    {/* <!-- <i className="fa-solid fa-chevron-left"></i> --> */}
                                                </div>
                                                <h5>Starred Messages</h5>
                                                <div className="call-setting-tab">
                                                    <div className="btn profile-setting-btn dropdown-toggle" data-bs-toggle="dropdown">
                                                        <i><FontAwesomeIcon icon={faEllipsisVertical} /></i>
                                                    </div>
                                                    <ul className="dropdown-menu dropdown-menu-dark">
                                                        <li><a className="dropdown-item" href="#">Unstar all</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="chat-box chat-box-left">
                                            <div className="star-message-box">
                                                <div className="contact-profile card-header">
                                                    <div className="user-info ">
                                                        <span className="sender-name">Sender-name</span>
                                                        <div className="dot"></div>
                                                        <span className="reciver-name">
                                                            You
                                                        </span>
                                                    </div>
                                                    <div className="time">
                                                        <span className="msg_time">Yesterday</span>
                                                        <i className="fa-solid fa-chevron-right"></i>
                                                    </div>

                                                </div>
                                                <div className="message">
                                                    <div className="msg_cotainer_send">
                                                        Hello david
                                                    </div>
                                                    <span className="msg_time_send">8:55 AM<i className="fa-solid fa-star"></i> </span>
                                                </div>
                                            </div>

                                            <div className="star-message-box">
                                                <div className="contact-profile card-header">
                                                    <div className="user-info ">
                                                        <span className="sender-name">Sender-name</span>
                                                        <div className="dot"></div>
                                                        <span className="reciver-name">
                                                            You
                                                        </span>
                                                    </div>
                                                    <div className="time">
                                                        <span className="msg_time">Yesterday</span>
                                                        <i className="fa-solid fa-chevron-right"></i>
                                                    </div>

                                                </div>
                                                <div className="message message-recive">
                                                    <div className="msg_cotainer">
                                                        Hello Andrew! I’m david
                                                    </div>
                                                    <span className="msg_time">6:00 PM<i className="fa-solid fa-star"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default StarredMessages;
