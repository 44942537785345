import { io } from "socket.io-client";

const socketUrl = process.env.REACT_APP_SOCKET_URL;
const userData = JSON.parse(localStorage.getItem('userData')) || null;
const userToken = localStorage.getItem('token');

const socket = io(socketUrl, {
  transports: ["websocket"],
  query: "userId=" + userData?.id,
});

socket.on('connect', () => {
  console.log('Socket.IO connection established');
});

  
  const onSend_message = async (data) => {
    if (data.sender_id !== userData?.id) {   
      console.log("update_message_status>>>>>>>>>>>>>>>`", data);
    if (data.length > 0) {
    }
    const updateMessageStatus = {
      Authorization: "Bearer " + userToken,
      message_ids: data.message_id,
      status: "2"
    };
    socket.emit("update_message_status", updateMessageStatus);
  }
  }; 
socket.on("message_received", onSend_message);




export default socket;
