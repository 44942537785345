import React, { useState, useEffect, useRef } from 'react'
import smile from "../../assets/images/emoji-btn.png"
import user_profile from "../../assets/images/user.png"
import chat_box from "../../assets/images/chat-box-img-2.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { performLogout } from '../../redux/actions/authActions'
import { setSettingDrawer, setProfileDrawer, setStarredDrawer } from '../../redux/actions/drawerActions';
import Profile from "../Profile"
import StarredMessages from '../StarredMessages/StarredMessages';
function Setting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileDrawerRef = useRef(null);
  const starredDrawerRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { isProfileOpen } = useSelector(state => state.drawer);
  const { isStarredOpen } = useSelector(state => state.drawer);
  const logout = async () => {
    Swal.fire({
      title: "Logout",
      text: "Are you sure you want to Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(performLogout(navigate));
      }
    });
  }
  useEffect(() => {
    // Add or remove event listener based on any drawer being open
    if (isStarredOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isStarredOpen]);
  const handleClickOutside = (event) => {
    if (!profileDrawerRef.current.contains(event.target)
    ) {
      console.log("%%%%%%%%%")
      dispatch(setStarredDrawer(false));
    }
  };
  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <div className="right-sidebar">
            <div className="main-contant">
              <div className="container-fluid">
                <section className="contant-box setting" id="chatSection">
                  <div className="row">

                    <div className="left-user-box">
                      <div className="contact-profile big">
                        <div className="call-setting-tab ms-0">
                          <div className="ms-0 btn" onClick={() => dispatch(setSettingDrawer(false))} >
                            <i className="fa-solid fa-arrow-left"></i>
                            <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                          </div>
                          <h5>New Group</h5>
                        </div>
                      </div>
                      <div className="chat-box chat-box-left">
                        <div className="serach-button-tab">
                          <div className="row">

                          </div>
                        </div>

                        <div className="contact-profile card-header p-0">
                          <div className="img-box">
                            <img src={userData.profile_pic || user_profile} alt="User profile" />
                          </div>
                          <div className="user-info ">
                            <h5 onClick={() => dispatch(setProfileDrawer(true))}>{userData.username}</h5>
                          </div>
                        </div>

                        <div className="setting-box">
                          <ul>

                            <li><a href="#">
                              <div className="img-box">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M6.625 14.6219C7.94741 14.3263 9.53663 14.0275 11.3222 13.7991V10.8752C11.3222 7.4686 14.0937 4.69718 17.5002 4.69718C20.9068 4.69718 23.6782 7.4686 23.6782 10.8752V13.7991C25.4638 14.0275 27.053 14.3263 28.3754 14.6219V10.8752C28.3754 4.8786 23.4968 0 17.5002 0C11.5036 0 6.625 4.8786 6.625 10.8752V14.6219Z"
                                    fill="white" />
                                  <path
                                    d="M17.5004 21.8292C16.7942 21.8292 16.2197 22.4037 16.2197 23.1098C16.2197 23.8161 16.7942 24.3906 17.5004 24.3906C18.2065 24.3906 18.781 23.8161 18.781 23.1098C18.7811 22.4037 18.2065 21.8292 17.5004 21.8292Z"
                                    fill="white" />
                                  <path
                                    d="M17.5002 15.4482C11.0551 15.4482 5.68616 16.909 3.84668 17.4756V32.972C5.68896 33.5382 11.0695 35 17.5002 35C23.9452 35 29.3142 33.5392 31.1536 32.9726V17.4761C29.3114 16.91 23.9309 15.4482 17.5002 15.4482ZM18.5256 26.2792V29.6444H16.4748V26.2792C15.1381 25.8457 14.1687 24.5891 14.1687 23.1098C14.1687 21.2729 15.6632 19.7784 17.5002 19.7784C19.3371 19.7784 20.8316 21.2729 20.8316 23.1098C20.8317 24.5891 19.8623 25.8457 18.5256 26.2792Z"
                                    fill="white" />
                                </svg>
                              </div>
                              <div className="setting-tab">
                                Privacy
                              </div>
                            </a>
                            </li>


                            <li><a href="#">
                              <div className="img-box">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1146_3663)">
                                    <path d="M17.5 0C7.82776 0 0 7.82688 0 17.5C0 27.1721 7.82688 35 17.5 35C27.1722 35 35 27.1731 35 17.5C35 7.82776 27.1731 0 17.5 0ZM16.9822 25.6908C15.9917 25.6908 15.2315 24.8615 15.2315 23.917C15.2315 22.9495 16.0147 22.1433 16.9822 22.1433C17.9497 22.1433 18.7558 22.9495 18.7558 23.917C18.7558 24.8614 17.9726 25.6908 16.9822 25.6908ZM19.6081 16.73C18.3412 17.7205 18.3181 18.4116 18.3181 19.6094C18.3181 20.0471 18.0878 20.5539 16.9591 20.5539C16.0146 20.5539 15.6922 20.2083 15.6922 19.0105C15.6922 17.0295 16.5675 16.085 17.2355 15.5092C17.9957 14.8641 19.2857 14.1501 19.2857 12.9062C19.2857 11.8465 18.3643 11.3398 17.2125 11.3398C14.8629 11.3398 15.3697 13.1136 14.1257 13.1136C13.5038 13.1136 12.7436 12.6988 12.7436 11.8005C12.7436 10.5567 14.1718 8.71377 17.2816 8.71377C20.2301 8.71377 22.1881 10.3493 22.1881 12.5146C22.1881 14.6799 20.2301 16.2463 19.6081 16.73Z" fill="white" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1146_3663">
                                      <rect width="35" height="35" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="setting-tab">
                                Help
                              </div>
                            </a>
                            </li>

                            <li><a href="#">
                              <div className="img-box">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1146_3663)">
                                    <path d="M17.5 0C7.82776 0 0 7.82688 0 17.5C0 27.1721 7.82688 35 17.5 35C27.1722 35 35 27.1731 35 17.5C35 7.82776 27.1731 0 17.5 0ZM16.9822 25.6908C15.9917 25.6908 15.2315 24.8615 15.2315 23.917C15.2315 22.9495 16.0147 22.1433 16.9822 22.1433C17.9497 22.1433 18.7558 22.9495 18.7558 23.917C18.7558 24.8614 17.9726 25.6908 16.9822 25.6908ZM19.6081 16.73C18.3412 17.7205 18.3181 18.4116 18.3181 19.6094C18.3181 20.0471 18.0878 20.5539 16.9591 20.5539C16.0146 20.5539 15.6922 20.2083 15.6922 19.0105C15.6922 17.0295 16.5675 16.085 17.2355 15.5092C17.9957 14.8641 19.2857 14.1501 19.2857 12.9062C19.2857 11.8465 18.3643 11.3398 17.2125 11.3398C14.8629 11.3398 15.3697 13.1136 14.1257 13.1136C13.5038 13.1136 12.7436 12.6988 12.7436 11.8005C12.7436 10.5567 14.1718 8.71377 17.2816 8.71377C20.2301 8.71377 22.1881 10.3493 22.1881 12.5146C22.1881 14.6799 20.2301 16.2463 19.6081 16.73Z" fill="white" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1146_3663">
                                      <rect width="35" height="35" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="setting-tab" onClick={() => dispatch(setStarredDrawer(true))}>
                                Starred Message
                              </div>
                            </a>
                            </li>


                            <li><a href="#">
                              <div className="img-box">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.5 0C7.85046 0 0 7.85046 0 17.5C0 27.1495 7.85046 35 17.5 35C27.1495 35 35 27.1495 35 17.5C35 7.85046 27.1495 0 17.5 0ZM23.1546 24.2704C21.3317 26.1138 18.9004 27.129 16.3085 27.129C10.999 27.129 6.67939 22.8095 6.67939 17.5C6.67939 12.1905 10.999 7.87097 16.3085 7.87097C18.9004 7.87097 21.3317 8.88617 23.1546 10.7296C23.4731 11.0517 23.4702 11.5711 23.1481 11.8896C22.8259 12.2082 22.3065 12.2053 21.988 11.8831C20.4757 10.3538 18.4587 9.51159 16.3085 9.51159C11.9036 9.51159 8.32009 13.0952 8.32009 17.5C8.32009 21.9048 11.9037 25.4884 16.3085 25.4884C18.4587 25.4884 20.4757 24.6462 21.988 23.1168C22.3066 22.7947 22.8259 22.7917 23.1481 23.1103C23.4702 23.4289 23.4732 23.9483 23.1546 24.2704ZM28.0803 18.08L25.2043 20.956C25.0441 21.1161 24.8342 21.1963 24.6243 21.1963C24.4143 21.1963 24.2044 21.1162 24.0442 20.956C23.7239 20.6356 23.7238 20.1162 24.0442 19.7958L25.5199 18.3203H16.3085C15.8555 18.3203 15.4882 17.953 15.4882 17.5C15.4882 17.047 15.8555 16.6797 16.3085 16.6797H25.5198L24.0442 15.2042C23.7239 14.8838 23.7238 14.3644 24.0442 14.044C24.3646 13.7236 24.884 13.7236 25.2044 14.044L28.0803 16.9199C28.2341 17.0737 28.3206 17.2824 28.3206 17.4999C28.3206 17.7175 28.2341 17.9262 28.0803 18.08Z" fill="#E63030" />
                                </svg>
                              </div>
                              <div className="setting-tab red" onClick={logout}>
                                Log out
                              </div>
                            </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

      </section >

      <div ref={profileDrawerRef} className={`drawer ${isProfileOpen ? 'open' : ''}`}>
        <Profile />
      </div>
      <div ref={starredDrawerRef} className={`drawer ${isStarredOpen ? 'open' : ''}`}>
        <StarredMessages />
      </div>

    </>
  )
}

export default Setting;