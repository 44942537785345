import { format } from "date-fns-tz";
import moment from "moment";
import Swal from "sweetalert2";

//empty check 
export function isEmpty(value) {
  return value === null || value === undefined || value === false || value === 0 || (typeof value === "string" && !value.trim());
}
export function validateStr(str) {
  return (str === null || str === undefined || str == "" || (typeof str === "string" && !str.trim())) ? false : true;
}
export function validateNum(num, len) {
  return (num === null || num === undefined || num == "" || parseInt(num).toString().length != len) ? false : true;
}

//email valid check
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

//phone valid function
export const validatePhoneNumber = (number) => {
  const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
  return phoneRegex.test(number);
};

//Swal sweet alert 
export function useToast() {
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
}
export function sortAlphabetic(a, b) {
  if (a.contact_name == null || b.contact_name == null)
    return 0;
  let usernameA = a.contact_name.toLowerCase();
  let usernameB = b.contact_name.toLowerCase();
  if (usernameA < usernameB)
    return -1;
  if (usernameA > usernameB)
    return 1;
  return 0;
}
export function sortByCreatedAt(a, b) {
  const dateA = new Date(a.created_at);
  const dateB = new Date(b.created_at);
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
}
export function convertDate(createdTime) {
  let date = new Date(createdTime);
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return formattedTime;
}
export function listTimeFormat(createdTime) {
  let date = new Date(createdTime);
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return formattedTime;
}

export function utcToLocalConvertor(date) {
  return new Date(Date.parse(date + ' UTC')).toLocaleString();
}
export function formatDateBasedOnRecency(utcDateStr) {
  if (utcDateStr == null || utcDateStr == undefined) {
    return '';
  }
  const localDate = new Date(Date.parse(utcDateStr + ' UTC')).toLocaleString();
  const date = new Date(utcDateStr);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.floor((now - date) / oneDay);


  let formattedDate;
  if (diffDays === 0) {
    formattedDate = `${date.toLocaleTimeString()}`;
    formattedDate = moment(localDate).format('hh:mm A')
  } else if (diffDays === 1) {
    formattedDate = `${date.toLocaleTimeString()}`;
  } else if (diffDays <= 7) {
    formattedDate = `${date.toLocaleDateString()}`;
  } else {
    formattedDate = date.toLocaleDateString();
  }
  return formattedDate;
}