import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import new_user_add from '../../assets/images/new-user-add.png'
import user from '../../assets/images/user.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, } from 'react-redux';
import { setAddGroupDrawer, setNewChatDrawer } from '../../redux/actions/drawerActions';
import { sortAlphabetic } from '../../utils/helpers/CommFun';
import SocketContext from '../../context/SocketContext';
function NewChat() {
    const dispatch = useDispatch();
    const { contactList } = useSelector(state => state.conversation);

    const [UserList, setUserList] = useState([])

    const userToken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const socket = useContext(SocketContext);

    const getContactList = async () => {
        const sortedList = [...contactList].sort(sortAlphabetic);
        setUserList(sortedList);
    }

    useEffect(() => {
        getContactList();
    }, [contactList]);  

    const createNewConv = async (data) => {
        let newConvList = [data.user_id, userData.id];
        const body = {
            Authorization: "Bearer " + userToken,
            user_id: userData.id,
            participant_ids: newConvList.toString(),
            conv_client_id: Date.now(),
        };
        console.log("createNewConv",body);
        if (socket) {    
            try {
                const data = await socket.emit("create_conversation", body);
                dispatch(setNewChatDrawer(false))
            } catch (error) {
                console.error("conversation not created", error)
            }
        } else {
            console.log("socket not ready error")
        }
    };

    return (
        <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn mx-3" onClick={() => dispatch(setNewChatDrawer(false))} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>New Chat</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left">
                <div className="serach-button-tab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="search" className="form-control" placeholder="Search name or number" />
                                <i><FontAwesomeIcon icon={faMagnifyingGlass} /></i>
                            </div>
                        </div>
                        <div className="new-group-box">
                            <div className="col-md-12">
                                <div onClick={() => dispatch(setAddGroupDrawer(true, false))} className="new-grp">
                                    <div className="col-2" >
                                        <img src={new_user_add} alt="upload-img" />
                                    </div>
                                    <div className="col-10">
                                        <span className="title">New Group</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div class=" flex-column justify-content-center">
                            <div class="image">
                              <img src="assets/images/profile-img.png" alt="" class="profile-update">
                              <input type="file" class="form-control" id="" placeholder="" alt=""
                              accept="image/gif, image/jpeg, image/png" />
                            </div>
                          </div> */}
                            {/* <div class="col-10">
                            <input type="text" class="form-control white" id="" placeholder="New Group" alt=""/>
                          </div> */}
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <p className="orange">Contact on Zebra</p>
                    {/* New chat page  */}
                    <div className="tab-pane fade active show" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">
                        <nav className="user-chat-nav newgroup-box" id="scrollbar">
                            {UserList.map((data, index) => (
                                <div key={index} className="nav-link" >
                                    <div className="user-chat-box" onClick={()=> createNewConv(data)}>
                                        <Link className="d-flex align-items-center">
                                            <div className="img-box">
                                                {data.profile_pic === null ?
                                                    <img
                                                        src={user}
                                                        alt=""
                                                    />
                                                    : <img
                                                        src={data.profile_pic}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                            <div className="user-info text-start">
                                                <h5>{data.contact_name}</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default NewChat