import api from '../../services/Api';

export const SET_CONV_LIST = 'SET_CONV_LIST';
export const SET_NEWGROUP_LIST = 'SET_NEWGROUP_LIST';
export const SET_CURR_CHAT = 'SET_CURR_CHAT';
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const SET_CURRCHAT_MEMBERS = 'SET_CURRCHAT_MEMBERS';

export const setCurrChat = (currChat) => ({
    type: SET_CURR_CHAT,
    payload: currChat
});
export const setGroupMembers = (currChatMembers) => ({
    type: SET_CURRCHAT_MEMBERS,
    payload: currChatMembers
});
export const setContactList = (currList) => ({
    type: SET_CONTACT_LIST,
    payload: currList
});
export const setConvList = (currList) => ({
    type: SET_CONV_LIST,
    payload: currList
});
export const setNewGroupList = (currList) => ({
    type: SET_NEWGROUP_LIST,
    payload: currList
});
export const getUserList = () => async (dispatch) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get("/conversation_list", userToken);
        if (response.code === 200) {
            dispatch(setConvList(response.result));
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
}

export const getMembersList = (convId) => async (dispatch) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get(`/web_group_member_list?conv_id=${convId}`, userToken);
        if (response.code === 200) {
            dispatch(setGroupMembers(response.result));
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
}

export const getContactList = (conversationList) => async (dispatch) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get("/web_user_contact_list", userToken);
        if (response.code === 200) {
            if (conversationList && conversationList.length > 0) {
                const existingConversationList = conversationList;
                const newList = response.result;
                const existingConvIds = new Set(existingConversationList.map(contact => contact.user_id));
                const filteredNewList = newList.filter(contact => !existingConvIds.has(contact.user_id));
                dispatch(setContactList(filteredNewList));

            } else {
                dispatch(setContactList(response.result));
            }
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
}
