import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/Api'
import user from '../../assets/images/user.png'
import nextIcon from '../../assets/images/next-icon.png'
import close from '../../assets/images/modal-close-btn.png'
import { useDispatch, useSelector } from 'react-redux';
import { setAddGroupDrawer, setNewChatDrawer, setNewGroupDrawer } from '../../redux/actions/drawerActions';
import { setNewGroupList } from '../../redux/actions/convListActions';
import { sortAlphabetic, useToast } from '../../utils/helpers/CommFun';

function AddGroupMember() {
    const dispatch = useDispatch();

    const { contactList, groupMembers, conversationList } = useSelector(state => state.conversation);
    const { isAddGroupOpen, isNewGroupOpen, isAddMember } = useSelector(state => state.drawer);
    const { currChat } = useSelector(state => state.conversation);
    const { newGroupList } = useSelector(state => state.conversation);

    //get token
    const usertoken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [error, seterror] = useState(null);
    const [UserList, setUserList] = useState([])
    const [SelectedUserList, setSelectedUserList] = useState([])
    const toast = useToast();

    //Get User list 
    const getContactList = async () => {
        const CotactConvList = [...contactList, ...conversationList];
        if (isAddMember) {
            const existingMembers = [...groupMembers];
            const existingUserIds = new Set(existingMembers.map(contact => contact.user_id));
            const filteredNewList = CotactConvList.filter(contact => !existingUserIds.has(contact.user_id));
            setSelectedUserList(existingMembers.sort(sortAlphabetic));
            setUserList(filteredNewList.sort(sortAlphabetic));
        } else {
            const sortedList = CotactConvList.sort(sortAlphabetic);
            setUserList(sortedList);
        }
    }
    const handleSelect = (data) => {
        const updatedUserList = UserList.filter(user => user.user_id !== data.user_id);
        const selectedUser = UserList.find(user => user.user_id === data.user_id);
        setUserList(updatedUserList.sort(sortAlphabetic));
        setSelectedUserList(prevData => [...prevData, selectedUser].sort(sortAlphabetic));
    }
    
    const handleCancel = (data) => {
        const updatedSelectedUserList = SelectedUserList.filter(user => user.user_id !== data.user_id);
        const cancelledUser = SelectedUserList.find(user => user.user_id === data.user_id);
        setSelectedUserList(updatedSelectedUserList.sort(sortAlphabetic));
        let userlist = [...UserList, cancelledUser].sort(sortAlphabetic);
        setUserList(userlist);
    }
    
    const addGroupMember = async (participantId = null) => {
        try {
            const body = {
                conv_id: currChat.conv_id,
                participant_ids: newGroupList.toString(),
            }
            if (participantId) {
                body.participant_ids = participantId.toString();
            }
            console.log(body);
            const response = await api.post(`/add_member`, body, usertoken);

            if (response) {
                toast.fire({
                    icon: "success",
                    title: response.message,
                });
                closeAddGroup();
            } else {
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    }



    const setParticipants = () => {
        let participantId = [];
        SelectedUserList.forEach((user) => {
            participantId.push(user.user_id);
        })
        console.log(participantId);
        if (isAddMember) { return participantId }
        dispatch(setNewGroupList(participantId));
    }
    const handleSubmit = () => {
        if (SelectedUserList.length > 0) {
            if (isAddMember) {
                addGroupMember(setParticipants());
            }
            else {
                setParticipants();
                dispatch(setNewGroupDrawer(true));
            }
        }
    }

    useEffect(() => {
        // addGroupMember();
    }, []);

    useEffect(() => {
        getContactList();
        setSelectedUserList([]);
    }, [contactList]);

    useEffect(() => {
        if (!isAddGroupOpen && !isNewGroupOpen) {
            getContactList();
            setSelectedUserList([]);
        }
    }, [isNewGroupOpen, isAddGroupOpen])
    const closeAddGroup = () => {
        dispatch(setNewChatDrawer(false))
        dispatch(setAddGroupDrawer(false, false));
        getContactList();
        setSelectedUserList([]);
    }
    return (
        <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn mx-3" onClick={() => { closeAddGroup() }} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>Add group members</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left">
                <div className="selected-user-tab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="selected-box" id="scroll">
                                {SelectedUserList.map((data, index) => (
                                    <div className="user-item" key={index}>
                                        <div className="nav-link">
                                            <div className="user-chat-box">
                                                <div className="d-flex align-items-center">
                                                    <div className="img-box">
                                                        {data.profile_pic === null ?
                                                            <img
                                                                src={user}
                                                                alt=""
                                                            />
                                                            : <img
                                                                src={data.profile_pic}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div className="user-info text-start">
                                                        <h5>{data.username != null ? data.username : data.contact_name}</h5>
                                                    </div>
                                                    <img onClick={() => handleCancel(data)} src={close} alt="" className="close" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    {/* create new group chat  */}
                    <div className="tab-pane fade active show" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">
                        <nav className="user-chat-nav newgroup-box" id="scrollbar">
                            {UserList.map((data, index) => (
                                <div className="nav-link" key={index} onClick={() => handleSelect(data)} >
                                    <div className="user-chat-box">
                                        <a className="d-flex align-items-center">
                                            <div className="img-box">
                                                {data.profile_pic === null ?
                                                    <img
                                                        src={user}
                                                        alt=""
                                                    />
                                                    : <img
                                                        src={data.profile_pic}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                            <div className="user-info text-start">
                                                <h5>{data.username != null ? data.username : data.contact_name}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                            <div className="nav-link">
                                <div className="user-chat-box">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="img-box">
                                            <img src="./assets/images/user.png" alt="" />
                                        </div>
                                        <div className="user-info text-start">
                                            <h5>User Name </h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="next-btn-box">
                    <div className="img-box" onClick={() => handleSubmit()}>
                        {/* <i><FontAwesomeIcon icon={faArrowRight} /></i> */}
                        <div className="img-box">
                          <img src={nextIcon} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddGroupMember