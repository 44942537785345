import React, { useContext, useEffect, useRef, useState,useMemo } from 'react'
import Chatwindow from './Chatwindow'
import profile from '../../assets/images/profile.png'
import new_chat from '../../assets/images/new-chat.png'
import user from '../../assets/images/user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { performLogout } from '../../redux/actions/authActions'
import NewChat from './NewChat';
import AddGroupMember from './AddGroupMember'
import NewGroup from './NewGroup'
import '../../assets/css/drawer.css';
import api from '../../services/Api'
import moment from 'moment/moment'
import { setAddGroupDrawer, setNewChatDrawer, setNewGroupDrawer, setSettingDrawer } from '../../redux/actions/drawerActions'
import { getContactList, getUserList, setConvList, setCurrChat } from '../../redux/actions/convListActions'
import Fuse from 'fuse.js';
import Setting from '../Setting/Setting'
import SocketContext from '../../context/SocketContext'
import { formatDateBasedOnRecency, utcToLocalConvertor } from '../../utils/helpers/CommFun'
import ContactInfo from './ContactInfo'


import { setProfileDrawer, setStarredDrawer } from '../../redux/actions/drawerActions';
import Profile from "../Profile"
import StarredMessages from '../StarredMessages/StarredMessages'
import { Prev } from 'react-bootstrap/esm/PageItem'

function Chatlist() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileDrawerRef = useRef(null);
    const starredDrawerRef = useRef(null);
    const socket = useContext(SocketContext);

    //get token
    const usertoken = localStorage.getItem('token')
    const { isNewChatOpen, isAddGroupOpen, isNewGroupOpen, isSettingsOpen } = useSelector(state => state.drawer);
    const [isChatWindowOpen, setChatWindowOpen] = useState(false);
    const [profilePic, setProfilePic] = useState('')



    const { conversationList } = useSelector(state => state.conversation);

    const [error, seterror] = useState(null);
    const [convList, setconvList] = useState([])
    const [groupList, setGroupList] = useState([])
    const [unreadList, setunreadList] = useState([])
    const [search, setSearch] = useState('')
    
    const newChatDrawerRef = useRef(null);
    const addGroupDrawerRef = useRef(null);
    const newGroupDrawerRef = useRef(null);
    const settingsDrawerRef = useRef(null);


    const { isProfileOpen } = useSelector(state => state.drawer);
    const { isStarredOpen } = useSelector(state => state.drawer);

    //Update Conversation List base on latest message

    //on event functions

    const onCreateGroup = (data) => {
        console.log("onCreateGroup", data);
        setconvList((prevMessages) => { 
                return [data, ...prevMessages];
        });
    };

    console.log("opop",convList);
    const onCreateconv = (data) => {
        
    };

    //on receive messages
    const onreceivedMessage = (data) => {

        setconvList((prevMessages) => { 
            const index = prevMessages.findIndex(message => message.conv_id === data.conv_id);
    
            if (index !== -1) {
                const updatedMessage = {
                    ...prevMessages[index],
                    message_id: data.message_id,
                    content: data.content,
                    client_id: data.client_id,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                };
    
                // Remove the old object and add the updated object at the beginning
                return [updatedMessage, ...prevMessages.filter((_, i) => i !== index)];
            } else {
                // If no matching conv_id is found, add the new message as a new conversation
                return [data, ...prevMessages];
            }
        });
      
    };

    // console.log("><><>><<><>",convList);

    // Socket events 
    useEffect(() => {
        socket.on("create_group", onCreateGroup);
        socket.on("create_conversation", onCreateconv);
        socket.on("message_received", onreceivedMessage);
        return () => {

            socket.off("create_group", onCreateGroup);
            socket.off("create_conversation", onCreateconv);
            socket.off("message_received", onreceivedMessage);
        };
    }, [socket]);
    

    //Set Profile picture
    useEffect(() => {
        const userDataString = localStorage.getItem('userData');
        if (userDataString) {
            const user = JSON.parse(userDataString);
            setProfilePic(user.profile_pic);
        } else {
            console.log('No user data found in local storage.');
        }
    },[]);

    //Get Conversation list
    useEffect(() => {
        dispatch(getUserList());
    }, [])
    
    // Get Contact for new chat list 
    useEffect(() => {        
        dispatch(getContactList(conversationList));
        setconvList(conversationList);
    }, [conversationList,dispatch])

    //Open chat window
    const setConversation = async (data) => {
        dispatch(setCurrChat(data));
        setChatWindowOpen(true);
    }

    //logout
    const logout = async () => {
        Swal.fire({
            title: "Logout",
            text: "Are you sure you want to Logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Logout",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(performLogout(navigate));
            }
        });
    };
    
    //Drawer open close management
    const handleClickOutside = (event) => {
        if (newChatDrawerRef.current &&
            !newChatDrawerRef.current.contains(event.target)
            && !newGroupDrawerRef.current.contains(event.target)
            && !addGroupDrawerRef.current.contains(event.target)
            && !settingsDrawerRef.current.contains(event.target)
            && !profileDrawerRef.current.contains(event.target)
        ) {
            console.log("%%%%%%%%%")
            dispatch(setNewChatDrawer(false));
            dispatch(setNewGroupDrawer(false));
            dispatch(setAddGroupDrawer(false, false));
            dispatch(setSettingDrawer(false));
            dispatch(setProfileDrawer(false));
            dispatch(setStarredDrawer(false));
        }
    };
    useEffect(() => {
        // Add or remove event listener based on any drawer being open
        if (isNewChatOpen || isNewGroupOpen || isAddGroupOpen || isSettingsOpen || isProfileOpen || isStarredOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNewChatOpen, isNewGroupOpen, isAddGroupOpen, isSettingsOpen, isProfileOpen, isStarredOpen]);

    return (
        <>
            {/* Chatlist */}
            <div className="col-md-5 col-lg-4 col-xxl-3 p-0">
                <div className="left-user-box">
                    <div className="contact-profile card-header">
                        <img src={profilePic || profile} alt="profile" onClick={() => dispatch(setProfileDrawer(true))} />
                        <div className="call-setting-tab">
                            <div className="btn" onClick={() => dispatch(setNewChatDrawer(true))}>
                                <img src={new_chat} alt="" />
                            </div>
                            <div
                                className="btn profile-setting-btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                            >
                                <i><FontAwesomeIcon icon={faEllipsisVertical} /></i>
                            </div>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <div className="dropdown-item" onClick={() => dispatch(setAddGroupDrawer(true, false)) /* open drawer for new group */}>
                                        New group
                                    </div>
                                </li>
                                <li>
                                    {/* <Link
                                        className="dropdown-item"
                                        onClick={toggleDrawer3}
                                    >
                                        Starred messages
                                    </Link> */}
                                </li>
                                <li>
                                    <div className="dropdown-item" onClick={() => dispatch(setSettingDrawer(true))} >
                                        Settings
                                    </div>
                                </li>
                                <li>
                                    <Link className="dropdown-item" onClick={logout}>
                                        Log out
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="chat-box chat-box-left">
                        <div className="serach-button-tab">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="search"
                                            className="form-control"
                                            id=""
                                            placeholder="Serach"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <i><FontAwesomeIcon icon={faMagnifyingGlass} /></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="">
                            <div
                                className="nav nav-tabs border-0 "
                                id="nav-tab"
                                role="tablist"
                            >
                                <button
                                    className="nav-link active d-flex justify-content-center align-items-center"
                                    id="nav-User-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-User"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-User"
                                    aria-selected="true"
                                >
                                    All
                                </button>

                                <button
                                    className="nav-link d-flex justify-content-center align-items-center"
                                    id="nav-Admin-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-Admin"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-Admin"
                                    aria-selected="false"
                                >
                                    Unread
                                </button>
                                <button
                                    className="nav-link d-flex justify-content-center align-items-center"
                                    id="nav-Group-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-Group"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-Group"
                                    aria-selected="false"
                                >
                                    Group
                                </button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {/* All Messages*/}
                            <div
                                className="tab-pane fade active show"
                                id="nav-User"
                                role="tabpanel"
                                aria-labelledby="nav-User-tab"
                            >
                                <nav className="user-chat-nav" id="scrollbar">
                                    {convList.map((data, index) => (
                                        <div
                                            onClick={() => setConversation(data)}
                                            className="nav nav-tabs border-0 "
                                            id="nav-tab"
                                            role="tablist"
                                            key={index}
                                        >
                                            <button
                                                className="nav-link chat-btn"
                                                type="button"
                                                role="tab"
                                            >
                                                <div className="user-chat-box">
                                                    <Link
                                                        className="d-flex align-items-center active"
                                                        id="chat-link">
                                                        <div className="img-box">
                                                            {data.profile_pic == null || data.group_icon == null ?
                                                                <img
                                                                    src={user}
                                                                    alt=""
                                                                />
                                                                : <img
                                                                    src={data.conv_type == 2 ? data.group_icon : data.profile_pic}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div className="user-info text-start">
                                                            <h5>{data.conv_type == 2 ? data.group_name : data.username}</h5>
                                                            <p>{data.content}</p>
                                                        </div>
                                                        <div className="chat-time ms-auto text-center">
                                                            <p className="time">{formatDateBasedOnRecency(data.created_at)}</p>
                                                            {data.unread_count != 0 &&
                                                                <span> {data.unread_count} </span>
                                                            }
                                                        </div>
                                                    </Link>
                                                </div>
                                            </button>
                                        </div>
                                    ))
                                    }
                                </nav>
                            </div>

                            <div
                                className="tab-pane fade"
                                id="nav-Admin"
                                role="tabpanel"
                                aria-labelledby="nav-Admin-tab"

                            >
                                <nav className="user-chat-nav" id="scrollbar">
                                    {unreadList.map((data, index) => (
                                        <div
                                            className="nav nav-tabs border-0 "
                                            id="nav-tab"
                                            role="tablist"
                                            key={index}
                                            onClick={() => setConversation(data)}
                                        >
                                            <button
                                                className="nav-link chat-btn"
                                                type="button"
                                                role="tab"
                                            >
                                                <div className="user-chat-box">
                                                    <Link

                                                        className="d-flex align-items-center active"
                                                        id="chat-link"
                                                    >
                                                        <div className="img-box">
                                                            {data.profile_pic === null && data.group_icon === null ?
                                                                <img
                                                                    src={user}
                                                                    alt=""
                                                                />
                                                                : <img
                                                                    src={data.conv_type == 2 ? data.group_icon : data.profile_pic}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div className="user-info text-start">
                                                            <h5>{data.conv_type == 2 ? data.group_name : data.username}</h5>
                                                            <p>{data.content}</p>
                                                        </div>
                                                        <div className="chat-time ms-auto text-center">
                                                            <p className="time">{formatDateBasedOnRecency(data.created_at)}</p>
                                                            <span> {data.unread_count} </span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </button>
                                        </div>
                                    ))}
                                </nav>
                            </div>

                            <div
                                className="tab-pane fade"
                                id="nav-Group"
                                role="tabpanel"
                                aria-labelledby="nav-Group-tab"

                            >
                                <nav className="user-chat-nav" id="scrollbar">
                                    {groupList.map((data, index) => (
                                        <div
                                            className="nav nav-tabs border-0 "
                                            id="nav-tab"
                                            role="tablist"
                                            key={index}
                                            onClick={() => setConversation(data)}
                                        >
                                            <button
                                                className="nav-link chat-btn"
                                                type="button"
                                                role="tab"
                                            >
                                                <div className="user-chat-box">
                                                    <Link

                                                        className="d-flex align-items-center active"
                                                        id="chat-link"
                                                    >
                                                        <div className="img-box">
                                                            {data.profile_pic === null && data.group_icon === null ?
                                                                <img
                                                                    src={user}
                                                                    alt=""
                                                                />
                                                                : <img
                                                                    src={data.conv_type == 2 ? data.group_icon : data.profile_pic}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div className="user-info text-start">
                                                            <h5>{data.conv_type == 2 ? data.group_name : data.username}</h5>
                                                            <p>{data.content}</p>
                                                        </div>
                                                        <div className="chat-time ms-auto text-center">
                                                            <p className="time">{formatDateBasedOnRecency(data.created_at)}</p>
                                                            <span> {data.unread_count} </span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </button>
                                        </div>
                                    ))}

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Chat */}
            <div ref={newChatDrawerRef} className={`drawer ${isNewChatOpen ? 'open' : ''}`}>
                <NewChat />
            </div>
            {/* Add New group member */}
            <div ref={addGroupDrawerRef} className={`drawer ${isAddGroupOpen ? 'open' : ''}`}>
                <AddGroupMember />
            </div>
            {/* New group */}
            <div ref={newGroupDrawerRef} className={`drawer ${isNewGroupOpen ? 'open' : ''}`}>
                <NewGroup />
            </div>

            <div ref={settingsDrawerRef} className={`drawer ${isSettingsOpen ? 'open' : ''}`}>
                <Setting />
            </div>

            <div ref={profileDrawerRef} className={`drawer ${isProfileOpen ? 'open' : ''}`}>
                <Profile />
            </div>

            {isChatWindowOpen && <Chatwindow />}
            
            
        </>
    )
}

export default Chatlist